import React, { FC } from 'react'

import { styled } from '@linaria/react'
import { FormattedMessage } from 'react-intl'

import { LOCALES } from 'common-constants/locale'
import { BottomSheetOnRoute } from 'components/designSystem/BottomSheet/BottomSheetOnRoute'
import { buttonResetCss } from 'components/designSystem/styles/buttonResetCss'
import { Typography } from 'components/designSystem/Typography/Typography'
import { changeLanguagePath } from 'components/page/Onboarding/paths'
import { useBaseUrl } from 'components/system/baseUrl/BaseUrlContext'
import { fullPageReload } from 'functions/fullPageReload'
import { mergeAllUrls } from 'functions/mergeAllUrls'

export const ChangeLanguageBottomSheet: FC = () => {
  const baseUrl = useBaseUrl()

  const handleSelect = (code: string) => {
    fullPageReload(mergeAllUrls(code))
  }

  return (
    <BottomSheetOnRoute
      path={mergeAllUrls(baseUrl, changeLanguagePath)}
      title={
        <FormattedMessage
          id="app.change_language"
          defaultMessage="Сменить язык"
        />
      }
    >
      {LOCALES.map(({ code, name }) => (
        <LanguageButton
          key={code}
          onClick={() => handleSelect(code)}
          data-name={`field-${code}-action`}
        >
          <Language fontSize={16} fontWeight={500}>
            {name}
          </Language>
        </LanguageButton>
      ))}
    </BottomSheetOnRoute>
  )
}

const LanguageButton = styled.button`
  ${buttonResetCss};
  display: block;
`
const Language = styled(Typography)`
  padding: var(--spacing-16px) 0;
  text-align: left;
`
