import React, { FC } from 'react'

import { styled } from '@linaria/react'
import { FormattedMessage } from 'react-intl'

import { LOCALES } from 'common-constants/locale'
import { Typography } from 'components/designSystem/Typography/Typography'
import { NavigationRouterLink } from 'components/navigation/NavigationRouterLink'
import { useBaseUrl } from 'components/system/baseUrl/BaseUrlContext'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

import { changeLanguagePath } from './paths'

export const ChangeLanguageButton: FC = () => {
  const { locale } = useShallowEqualSelector(
    ({ systemReducer: { locale } }) => ({
      locale,
    })
  )

  const baseUrl = useBaseUrl()

  const languageName = LOCALES.find(({ code }) => code === locale)?.name

  return (
    <div>
      <NavigationRouterLink
        to={mergeAllUrls(baseUrl, changeLanguagePath)}
        replaceIfNotBaseUrl={true}
        data-name="change-language-action"
      >
        <ChangeLanguageText fontSize={11}>
          <Language>
            <FormattedMessage id="app.language" defaultMessage="Язык" />
          </Language>
          : {languageName}
        </ChangeLanguageText>
      </NavigationRouterLink>
    </div>
  )
}

const ChangeLanguageText = styled(Typography)`
  color: var(--accent-primary-default);
`
const Language = styled.span`
  color: var(--foreground-muted);
`
