import React, { FC } from 'react'

import { styled } from '@linaria/react'
import { FormattedMessage } from 'react-intl'

import { isMamba } from 'common/constants'
import { MainLogo } from 'components/block/MainLogo/MainLogo'
import { RouterLink } from 'components/designSystem/Link/RouterLink'
import { breakpoints } from 'components/designSystem/shared/breakpoints'
import { Typography } from 'components/designSystem/Typography/Typography'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

export const WelcomePageTitleBlock: FC<{ welcomeBack: boolean }> = ({
  welcomeBack,
}) => {
  const { locale, partnerId } = useShallowEqualSelector(
    ({ systemReducer: { locale, partnerId } }) => ({
      locale,
      partnerId,
    })
  )

  return (
    <WelcomePageTitleBlockHeader>
      <RouterLink to={mergeAllUrls(locale)} data-name="go-to-index-page-action">
        <MainLogo />
      </RouterLink>
      <Title fontSize={32} fontWeight={700}>
        {welcomeBack && (
          <FormattedMessage
            id="app.welcome_back"
            defaultMessage="С возвращением"
          />
        )}
        {!welcomeBack && isMamba(partnerId) && (
          <FormattedMessage
            id="app.your.new.meets"
            defaultMessage="Твои новые{br}знакомства"
            values={{ br: <br /> }}
          />
        )}
      </Title>
    </WelcomePageTitleBlockHeader>
  )
}

const WelcomePageTitleBlockHeader = styled.header`
  flex-basis: 40%;

  @media (min-width: ${breakpoints.mobile}px) {
    flex-basis: 50%;
    padding-top: var(--spacing-80px);
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  padding-top: var(--spacing-32px);
  padding-bottom: var(--spacing-32px);
`
const Title = styled(Typography)`
  margin-top: var(--spacing-32px);
`
