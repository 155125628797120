import React, { FC } from 'react'

import { styled } from '@linaria/react'
import { FormattedMessage } from 'react-intl'

import { BottomSheetOnRoute } from 'components/designSystem/BottomSheet/BottomSheetOnRoute'
import { RouterLink } from 'components/designSystem/Link/RouterLink'
import { Typography } from 'components/designSystem/Typography/Typography'
import { cityListPath } from 'components/page/Boarding/boarding.paths'
import citiesLinks from 'components/page/Boarding/city-links.json'
import { useBaseUrl } from 'components/system/baseUrl/BaseUrlContext'
import { mergeAllUrls } from 'functions/mergeAllUrls'

export const CitiesBottomSheet: FC = () => {
  const baseUrl = useBaseUrl()

  return (
    <BottomSheetOnRoute
      path={mergeAllUrls(baseUrl, cityListPath)}
      title={
        <FormattedMessage id="boarding.footer.cities" defaultMessage="Города" />
      }
    >
      <div>
        {_citiesLinks.map(({ name, to }, index) => {
          return (
            <Country key={to} fontSize={16} fontWeight={500}>
              <RouterLink key={index} to={to} data-name={`link-${index}`} black>
                {name}
              </RouterLink>
            </Country>
          )
        })}
      </div>
    </BottomSheetOnRoute>
  )
}

const _citiesLinks = citiesLinks as unknown as {
  name: string
  to: string
}[]

const Country = styled(Typography)`
  padding: var(--spacing-16px) 0;
  text-align: left;

  & > * {
    color: var(--foreground-default);
  }
`
