import React, { FC } from 'react'

import styled from 'styled-components'

import { Loader } from 'components/designSystem/Loader/Loader'
import { formaDJRCyrillicMicroFontFamily } from 'components/designSystem/shared/fonts/formaDJRCyrillic/formaDJRCyrillicFontFamily'
import { PlainWrapper } from 'components/page/Legal/PlainWrapper'

export const AgreementTextNext: FC<{
  text?: string | null
  textLoading: boolean
}> = ({ text, textLoading }) => {
  if (!text || textLoading) {
    return <Loader />
  }

  return (
    <Text>
      <PlainWrapper text={text.replace('_target', '_blank')} loading={false} />
    </Text>
  )
}

const Text = styled.div`
  font-family: ${formaDJRCyrillicMicroFontFamily};
  text-align: left;

  & > * {
    margin: 0;
  }

  h1 {
    color: var(--warm-hard);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 116.667% */
    letter-spacing: 0.48px;
    text-align: center;
  }

  ol > li > p {
    display: inline;
  }

  div > ol > li > p {
    display: inline;
    font-weight: 700;
  }
`
