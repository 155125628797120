import React, { FC } from 'react'

import { styled } from '@linaria/react'
import { FormattedMessage } from 'react-intl'

import { Copyright } from 'components/block/Footer/Copyright'
import { Anchor } from 'components/designSystem/Link/Anchor'
import { RouterLink } from 'components/designSystem/Link/RouterLink'
import { breakpoints } from 'components/designSystem/shared/breakpoints'
import { Typography } from 'components/designSystem/Typography/Typography'
import { appAgreementIndexPath, confidentialityPath } from 'components/paths'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

import { supportFormPath } from '../Boarding/SupportForm/SupportForm.paths'

export const FooterInfo: FC = () => {
  const { pathname, locale } = useShallowEqualSelector(
    ({
      router: {
        location: { pathname },
      },
      systemReducer: { locale },
    }) => ({ pathname, locale })
  )

  return (
    <AgreementTextContainer>
      <FormattedMessage
        id="app.you.accept.agreement_2"
        defaultMessage="Продолжая, ты принимаешь условия{br} {agreement} и {confidentiality}"
        values={{
          agreement: (
            <RouterLink
              to={mergeAllUrls(pathname, appAgreementIndexPath)}
              data-name="footer-agreement-mobile-action"
            >
              <FormattedMessage
                id="boarding.social_media_conditions_agreement"
                defaultMessage="Соглашения"
              />
            </RouterLink>
          ),
          confidentiality: (
            <RouterLink
              to={mergeAllUrls(pathname, confidentialityPath)}
              data-name="footer-confidentiality-mobile-action"
            >
              <FormattedMessage
                id="boarding.confidentiality_2"
                defaultMessage="Конфиденциальности"
              />
            </RouterLink>
          ),
          br: <Br />,
        }}
      >
        {(chunks) => <Text fontSize={11}>{chunks}</Text>}
      </FormattedMessage>

      <Text fontSize={11}>
        <FormattedMessage
          id="app.we.use.recommendation.technologies"
          defaultMessage="Мы используем <a>Рекомендательные технологии</a>"
          values={{
            a: (chunks) => (
              <Anchor
                href="https://corp.mamba.ru/recommendation"
                target="_blank"
                data-name="recommendation-technologies-action"
              >
                {chunks}
              </Anchor>
            ),
          }}
        />
      </Text>

      <Typography fontSize={11}>
        <RouterLink
          to={mergeAllUrls(locale, supportFormPath)}
          data-name="support-action"
        >
          <FormattedMessage
            id="app.support"
            defaultMessage="Служба поддержки"
          />
        </RouterLink>
      </Typography>

      <DesktopTypography fontSize={11}>
        <Copyright />
      </DesktopTypography>
    </AgreementTextContainer>
  )
}

const Text = styled(Typography)`
  color: var(--foreground-muted);
`
const DesktopTypography = styled(Typography)`
  color: var(--foreground-muted);

  a {
    /* Переписываем стиль тег "a", потому что используется старый компонент. */
    color: var(--info-soft);
  }

  @media screen and (max-width: ${breakpoints.mobile - 1}px) {
    display: none;
  }
`
const Br = styled.br`
  @media screen and (min-width: ${breakpoints.mobile}px) {
    display: none;
  }
`
const AgreementTextContainer = styled.div`
  margin-top: auto;
  padding: var(--spacing-16px) 0;
  text-align: center;

  & > * + * {
    margin-top: var(--spacing-8px);
  }
`
