import React, { FC } from 'react'

import { styled } from '@linaria/react'

import { breakpoints } from 'components/designSystem/shared/breakpoints'

import { ChangeLanguageButton } from './ChangeLanguageButton'

export const HeaderMobile: FC<{ visible: boolean }> = ({ visible }) => {
  return (
    <ChangeLanguageButtonWrapper
      style={{
        opacity: visible ? 1 : 0,
        pointerEvents: visible ? 'all' : 'none',
      }}
    >
      <ChangeLanguageButton />
    </ChangeLanguageButtonWrapper>
  )
}

const ChangeLanguageButtonWrapper = styled.div`
  width: 100%;
  margin-top: var(--spacing-8px);
  margin-bottom: var(--spacing-8px);
  transition: opacity 0.3s;

  html[dir='ltr'] & {
    text-align: right;
  }

  html[dir='rtl'] & {
    text-align: left;
  }

  @media screen and (min-width: ${breakpoints.mobile}px) {
    display: none;
  }
`
